import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { getMsalConfig } from "./utils/msalConfig.js";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { ChatScreen } from "./screens/ChatScreen.jsx";
import { JoyrideProvider } from "./utils/contexts/JoyrideContext.jsx";
import { GraphClientProvider } from "./utils/contexts/GraphClientContext.jsx";
import { StripeConfirmation } from "./screens/StripeConfirmation.jsx";

// Initialize authentication using MSAL
const msalInstance = new PublicClientApplication(getMsalConfig());
await msalInstance.initialize();
// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  console.log(msalInstance.getActiveAccount());
}
// Listen for sign-in event to set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// Setup client-side routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/stripe-confirmation",
    element: <StripeConfirmation />,
  },
  {
    path: "/claire",
    element: <ChatScreen />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <GraphClientProvider>
        <JoyrideProvider>
          <RouterProvider router={router} />
        </JoyrideProvider>
      </GraphClientProvider>
    </MsalProvider>
  </React.StrictMode>
);

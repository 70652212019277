import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import StartAudioIcon from "../assets/start-audio.svg";
import StopAudioIcon from "../assets/stop-audio.svg";
import { removeHtmlTags } from "../utils/removeHtmlTags";
import { useRef, useState } from "react";

const subscriptionKey = import.meta.env.VITE_STT_SUBSCRIPTION_KEY;
const serviceRegion = import.meta.env.VITE_STT_SERVICE_REGION;

const TextToSpeechButton = ({ text, setShowTextToSpeechTooltip }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const playerRef = useRef(null);

  const startSpeaking = () => {
    const player = new sdk.SpeakerAudioDestination();
    player.onAudioEnd = () => {
      setIsSpeaking(false);
      setShowTextToSpeechTooltip(true);
    };
    playerRef.current = player;
    const speechConfig = sdk.SpeechConfig.fromSubscription(
      subscriptionKey,
      serviceRegion
    );
    speechConfig.speechSynthesisVoiceName = "en-US-AvaMultilingualNeural";
    const audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);
    const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
    const plainText =
      typeof text === "string"
        ? text
        : removeHtmlTags(text.props.dangerouslySetInnerHTML.__html);

    synthesizer.speakTextAsync(
      plainText,
      (result) => {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          console.log(`Synthesized speech`);
        } else if (result.reason === sdk.ResultReason.Canceled) {
          const cancellationDetails =
            sdk.SpeechSynthesisCancellationDetails.fromResult(result);
          console.log(
            `Speech synthesis canceled: ${cancellationDetails.reason}`
          );
        }
        synthesizer.close();
      },
      (error) => {
        console.log(error);
        synthesizer.close();
      }
    );
  };

  const stopSpeaking = async () => {
    playerRef.current.pause();
  };

  const handleButtonClick = () => {
    if (isSpeaking) {
      stopSpeaking();
      setShowTextToSpeechTooltip(true);
    } else {
      startSpeaking();
      setShowTextToSpeechTooltip(false);
    }
    setIsSpeaking(!isSpeaking);
  };

  return (
    <button
      className="secondaryIconButton"
      onClick={handleButtonClick}
      aria-label="text to speech"
    >
      <img src={isSpeaking ? StopAudioIcon : StartAudioIcon} alt="" />
    </button>
  );
};

export default TextToSpeechButton;

import { useState, useRef } from "react";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import MicIcon from "../assets/mic.svg";

const subscriptionKey = import.meta.env.VITE_STT_SUBSCRIPTION_KEY;
const serviceRegion = import.meta.env.VITE_STT_SERVICE_REGION;

const SpeechToTextButton = ({ setInputValue, setShowSpeechToTextTooltip }) => {
  const [isListening, setIsListening] = useState(false);
  const recognizerRef = useRef(null);

  const startListening = () => {
    const speechConfig = sdk.SpeechConfig.fromSubscription(
      subscriptionKey,
      serviceRegion
    );
    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

    recognizer.recognized = (_s, e) => {
      if (e.result.text) {
        setInputValue((prevText) => prevText + " " + e.result.text);
      }
    };

    recognizer.sessionStarted = () => {
      console.log("Started recording.");
    };

    recognizer.sessionStopped = () => {
      console.log("Stopped recording.");
    };

    recognizer.startContinuousRecognitionAsync();
    recognizerRef.current = recognizer;
  };

  const stopListening = async () => {
    recognizerRef.current.stopContinuousRecognitionAsync();
  };

  const handleButtonClick = () => {
    if (isListening) {
      stopListening();
      setShowSpeechToTextTooltip(true);
    } else {
      startListening();
      setShowSpeechToTextTooltip(false);
    }
    setIsListening(!isListening);
  };

  return (
    <button
      className={`secondaryIconButton speechToText${
        isListening ? "pulsateBackground" : ""
      }`}
      onClick={handleButtonClick}
      aria-label={isListening ? "stop speech to text" : "start speech to text"}
    >
      <img src={MicIcon} alt="" style={{ height: "25px" }} />
    </button>
  );
};

export default SpeechToTextButton;

import { useEffect, useRef, useState } from "react";
import { ChatBubble } from "./ChatBubble";
import { InputBar } from "./InputBar";
import { Header } from "./Header";
import styles from "../styles/Messages.module.css";

export const Messages = ({
  messages,
  setMessages,
  bubbleRefs,
  toggleSidebarView,
  isToggleReversed,
  scrollWrapperRef,
  setShowSettingsModal,
}) => {
  const [typingIndicatorIsActive, setTypingIndicatorIsActive] = useState(false);
  const messagesEndRef = useRef(null);
  const prevMessagesLengthRef = useRef(messages.length);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // only scroll to bottom if new message was added
    if (messages.length !== prevMessagesLengthRef.current) {
      scrollToBottom();
      prevMessagesLengthRef.current = messages.length;
    }
  }, [messages]);

  return (
    <main className={styles.messagesContainer}>
      <button
        className={`sidebar-toggle sidebarToggleOutside ${styles.sidebarToggleOutside} ${isToggleReversed}`}
        onClick={toggleSidebarView}
        aria-label={isToggleReversed ? "open sidebar" : "close sidebar"}
      />
      <Header setShowSettingsModal={setShowSettingsModal} />
      <div className={styles.scrollWrapper} ref={scrollWrapperRef}>
        <div className={styles.chatBubblesContainer}>
          {messages.map((message, index) => {
            if (message === null) return null;
            return (
              <ChatBubble
                key={message.messageID}
                message={message}
                setMessages={setMessages}
                bubbleRefs={bubbleRefs}
                isLast={index === messages.length - 1}
                typingIndicatorIsActive={typingIndicatorIsActive}
              />
            );
          })}
        </div>
        <div ref={messagesEndRef} />
      </div>
      <InputBar
        setMessages={setMessages}
        setTypingIndicatorIsActive={setTypingIndicatorIsActive}
        scrollToBottom={scrollToBottom}
        scrollWrapperRef={scrollWrapperRef}
      />
    </main>
  );
};

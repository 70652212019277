import styles from "../styles/Socials.module.css";
import Twitter from "../assets/twitter.png";
import Linkedin from "../assets/linkedin.png";
import Instagram from "../assets/instagram.png";
import Github from "../assets/github.png";
import Facebook from "../assets/facebook.png";

export const Socials = () => {
  const socials = [
    {
      name: "twitter",
      link: "https://twitter.com/ClarifiTech",
      image: Twitter,
    },
    {
      name: "linkedin",
      link: "https://www.linkedin.com/company/clarifitechnologies/",
      image: Linkedin,
    },
    {
      name: "instagram",
      link: "https://www.instagram.com/clarifitech",
      image: Instagram,
    },
    { name: "github", link: "https://github.com/clarifi-tech", image: Github },
    {
      name: "facebook",
      link: "https://www.facebook.com/profile.php?id=61557457456764",
      image: Facebook,
    },
  ];

  return (
    <nav className={styles.socialsContainer}>
      {socials.map((social, index) => (
        <a
          className="secondaryIconButton"
          href={social.link}
          key={index}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Clarifi's ${social.name} page (opens in a new tab)`}
        >
          <img src={social.image} alt="" />
        </a>
      ))}
    </nav>
  );
};

import { removeHtmlTags } from "../utils/removeHtmlTags";
import styles from "../styles/Sidebar.module.css";
import bubbleStyles from "../styles/ChatBubble.module.css";
import FilledBookmark from "../assets/bookmark-filled.svg";

export const Sidebar = ({
  bookmarkedMessages,
  bubbleRefs,
  showSidebar,
  setShowSidebar,
  toggleSidebarView,
  isToggleReversed,
  scrollWrapperRef,
}) => {
  const isSidebarOpen = showSidebar ? styles.sidebarOpen : "";

  const handlSidebarBookmarkClick = (messageID) => {
    const bubble = bubbleRefs.current[messageID];
    if (window.innerWidth < 900) setShowSidebar(false);

    // Scroll message into view and highlight it
    const offsetFromParent = bubble.offsetTop;
    const additionalOffset = 70;
    const offsetPostion = offsetFromParent - additionalOffset;
    scrollWrapperRef.current.scrollTo({
      top: offsetPostion,
      behavior: "smooth",
    });
    bubble.classList.add(bubbleStyles.transientBgColor);
    setTimeout(() => {
      bubble.classList.remove(bubbleStyles.transientBgColor);
    }, 700);
  };

  return (
    <aside className={`${styles.sidebar} ${isSidebarOpen}`}>
      <header className={styles.header}>
        <h4>Chat Notes</h4>
        <button
          className={`sidebar-toggle  ${styles.sidebarToggleInside} ${isToggleReversed}`}
          onClick={toggleSidebarView}
          aria-label={isToggleReversed ? "open sidebar" : "close sidebar"}
        />
      </header>
      <main className={styles.content}>
        <div className={styles.bookmarks}>
          <h4 className={styles.bookmarksTitle}>
            <img
              src={FilledBookmark}
              alt=""
              style={{ filter: "brightness(0) invert(1)" }}
            />
            Bookmarks
          </h4>
          <ul className={styles.bookmarksList}>
            {bookmarkedMessages.map((msg) => (
              <li
                key={msg.messageID}
                onClick={() => handlSidebarBookmarkClick(msg.messageID)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handlSidebarBookmarkClick(msg.messageID);
                  }
                }}
              >
                <button>
                  <div>
                    {msg.textContent.props
                      ? removeHtmlTags(
                          msg.textContent.props.dangerouslySetInnerHTML.__html
                        ).substring(0, 50)
                      : msg.textContent}
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </main>
    </aside>
  );
};

import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "./msalConfig";

const useValidateAccessToken = () => {
  const { instance, accounts } = useMsal();
  const [hasValidAccessToken, setHasValidAccessToken] = useState(false);

  useEffect(() => {
    const request = {
      account: accounts[0],
      scopes: loginRequest.scopes,
    };

    const getAccessToken = async () => {
      try {
        const res = await instance.acquireTokenSilent(request);
        setHasValidAccessToken(true);
        console.log("Acquired access token:", res);
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(request);
        } else {
          console.error("Error acquiring access token:", err);
        }
      }
    };
    getAccessToken();
  }, [instance, accounts]);

  return hasValidAccessToken;
};

export { useValidateAccessToken };

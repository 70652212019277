import { createContext, useEffect, useState } from "react";
import { Client } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { useMsal } from "@azure/msal-react";
import { useValidateAccessToken } from "../useValidateAccessToken";

export const GraphClientContext = createContext(null);

export const GraphClientProvider = ({ children }) => {
  // Initialize Microsoft Graph Client to access/update user profile details
  const hasValidAccessToken = useValidateAccessToken();
  const { instance } = useMsal();
  const [valueProp, setValueProp] = useState({
    graphClient: null,
    userDetails: null,
  });

  useEffect(() => {
    if (hasValidAccessToken) {
      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        instance,
        { scopes: ["User.ReadWrite"] }
      );
      const graphClient = Client.initWithMiddleware({ authProvider });

      const fetchUserDetails = async () => {
        try {
          const userDetails = await graphClient.api("/me").get();
          setValueProp({ graphClient, userDetails });
        } catch (error) {
          console.error("Error fetching user graph details:", error);
        }
      };
      fetchUserDetails();
    }
  }, [hasValidAccessToken, instance]);

  return (
    <GraphClientContext.Provider value={valueProp}>
      {children}
    </GraphClientContext.Provider>
  );
};

import styles from "../styles/SettingsModal.module.css";
import ArrowIcon from "../assets/back-arrow.svg";
import NewTabIcon from "../assets/open-new-tab.svg";
import AvatarIcon from "../assets/avatar-white.svg";
import { useContext, useState } from "react";
import { GraphClientContext } from "../utils/contexts/GraphClientContext";
import { useMsal } from "@azure/msal-react";

const PrivacyView = () => {
  const openPDF = (PDFname) => {
    window.open(`/${PDFname}.pdf`, "_blank", "noopener,noreferrer");
  };

  return (
    <div className={styles.privacyView}>
      <h3>Privacy</h3>
      <p>
        To view our Privacy Policy, Terms of Service, and End User License
        Agreement, click below.
      </p>
      <div className={styles.buttonsContainer}>
        <button
          className="primaryButton"
          onClick={() => openPDF("clarifi-privacy-policy")}
          aria-label="Privacy Policy. Opens in a new tab"
        >
          Privacy Policy
          <img src={NewTabIcon} alt="" />
        </button>
        <button
          className="primaryButton"
          onClick={() => openPDF("clarifi-terms-of-service")}
          aria-label="Terms of Service. Opens in a new tab"
        >
          Terms of Service
          <img src={NewTabIcon} alt="" />
        </button>
        <button
          className="primaryButton"
          onClick={() => openPDF("claire-end-user-licensing-agreement")}
          aria-label="End User Licensing Agreement (EULA). Opens in a new tab"
        >
          EULA
          <img src={NewTabIcon} alt="" />
        </button>
      </div>
    </div>
  );
};

const ProfileView = ({ userDetails }) => (
  <div className={styles.profileView}>
    <h3>Profile</h3>
    <div>
      <div className={styles.leftDiv}>
        <img src={AvatarIcon} alt="User avatar" />
        <h4>Plus Member Claire for Professionals</h4>
      </div>
      <div className={styles.rightDiv}>
        <h3>{userDetails?.displayName}</h3>
        <p>Email</p>
        <p className={styles.indent}>{userDetails?.mail}</p>
      </div>
    </div>
  </div>
);

const SubscriptionView = () => (
  <div className={styles.subscriptionView}>
    <h3>Subscription</h3>
    <p>Manage your subscription with Stripe.</p>
    <div>
      <button className="primaryButton">Launch Stripe</button>
    </div>
  </div>
);

const DeleteAccountView = ({ userID }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();

  const handleDeleteClick = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://prodeleteuser.azurewebsites.net/api/delete-user?code=${
          import.meta.env.VITE_PRO_DELETE_USER
        }`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_id: userID }),
        }
      );
      if (response.ok) instance.logout();
      console.log("Deleted account");
    } catch (e) {
      console.error("Error deleting user account", e);
    }
  };

  const defaultContent = () => (
    <>
      <p>To delete account please contact us at info@clarifi-tech.com.</p>
      {/* <p>To delete your account, proceed below.</p>
      <div>
        <button
          className="primaryButton"
          onClick={() => setShowDeleteConfirmation(true)}
        >
          Delete Account
        </button>
      </div> */}
    </>
  );

  const confirmationContent = () => (
    <>
      <p>Please read the following information carefully before proceeding.</p>
      <div>
        <strong>Data Deletion</strong>
        <br />
        All your personal data, settings, and preferences will be permanently
        removed from our servers.
      </div>
      <div>
        <strong>Service Termination</strong>
        <br />
        Any active subscriptions or services linked to your account will be
        immediately terminated.
      </div>
      <div>
        <strong>No Recovery</strong>
        <br />
        Once your account is deleted, there is no option to recover or restore
        your information.
      </div>
      <div>
        <button className="primaryButton" onClick={handleDeleteClick}>
          Confirm Deletion
        </button>
      </div>
    </>
  );

  return (
    <div className={styles.deleteAccountView}>
      <h3>Delete Your Account</h3>
      {!loading && !showDeleteConfirmation && defaultContent()}
      {!loading && showDeleteConfirmation && confirmationContent()}
      {loading && <p>Loading...</p>}
    </div>
  );
};

export const SettingsModal = ({ setShowSettingsModal }) => {
  const [currentView, setCurrentView] = useState("privacy");
  const { userDetails } = useContext(GraphClientContext);

  const views = [
    { name: "privacy", jsx: <PrivacyView /> },
    {
      name: "profile",
      jsx: <ProfileView userDetails={userDetails} />,
    },
    // { name: "subscription", jsx: <SubscriptionView /> },
    {
      name: "delete account",
      jsx: <DeleteAccountView userID={userDetails?.id} />,
    },
  ];

  const renderCurrentView = () => {
    const view = views.find((v) => v.name === currentView);
    return view.jsx;
  };

  const handleBackButtonClick = () => {
    setShowSettingsModal(false);
  };

  return (
    <main className={styles.modalContainer}>
      <div className={styles.modalContent}>
        <button
          className={`secondaryIconButton ${styles.backButton}`}
          onClick={handleBackButtonClick}
        >
          <img src={ArrowIcon} alt="Close settings modal" />
        </button>
        <ul>
          {views.map((view) => (
            <li key={view.name}>
              <button
                className={currentView === view.name ? styles.active : ""}
                onClick={() => setCurrentView(view.name)}
              >
                {view.name.charAt(0).toUpperCase() + view.name.slice(1)}
              </button>
            </li>
          ))}
        </ul>
        <section className={styles.detailsContainer}>
          {renderCurrentView()}
        </section>
      </div>
    </main>
  );
};

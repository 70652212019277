import { useState, useRef, useContext } from "react";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import SoapNotesIcon from "../assets/soap-notes.svg";
import { streamClairesReply } from "../utils/streamClairesReply";
import { createMessage } from "../utils/helperFuntions";
import { GraphClientContext } from "../utils/contexts/GraphClientContext";

const subscriptionKey = import.meta.env.VITE_STT_SUBSCRIPTION_KEY;
const serviceRegion = import.meta.env.VITE_STT_SERVICE_REGION;

const getSoapNotes = async (
  userID,
  text,
  setMessages,
  setTypingIndicatorIsActive
) => {
  // Add empty Claire message
  const emptyClaireMessage = createMessage(1, "", false);
  setMessages((currentMessages) => [...currentMessages, emptyClaireMessage]);
  setTypingIndicatorIsActive(true);

  try {
    const response = await fetch("/api/generate_visit_notes", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user_id: userID, text_data: text }),
    });
    if (!response.ok) throw new Error("Failed to get SOAP notes");
    const data = await response.json();

    // Update recent message with soap notes
    setMessages((messageHistory) => {
      const updatedHistory = [...messageHistory];
      const lastChatIndex = updatedHistory.length - 1;
      updatedHistory[lastChatIndex] = {
        ...updatedHistory[lastChatIndex],
        soapNotes: data.notes.SOAP_note,
      };
      return updatedHistory;
    });
    return { error: false };
  } catch (err) {
    // Display error message to user
    const errMessage =
      "Sorry, it looks like we didn't catch that. It's possible your recording was too short or there was an issue with your microphone. Please make sure Claire can access your microphone and try again.";
    setMessages((messageHistory) => {
      const updatedHistory = [...messageHistory];
      const lastChatIndex = updatedHistory.length - 1;
      updatedHistory[lastChatIndex] = {
        ...updatedHistory[lastChatIndex],
        textContent: errMessage,
      };
      return updatedHistory;
    });
    console.error(err);
    return { error: true };
  } finally {
    setTypingIndicatorIsActive(false);
  }
};

const SoapNotesButton = ({
  setMessages,
  setTypingIndicatorIsActive,
  scrollWrapperRef,
  scrollToBottom,
  setShowSoapNotesTooltip,
}) => {
  const [isListening, setIsListening] = useState(false);
  const [text, setText] = useState("");
  const recognizerRef = useRef(null);
  const { userDetails } = useContext(GraphClientContext);

  const startListening = () => {
    const speechConfig = sdk.SpeechConfig.fromSubscription(
      subscriptionKey,
      serviceRegion
    );
    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

    recognizer.recognized = (_s, e) => {
      if (e.result.text) setText((prevText) => prevText + " " + e.result.text);
    };

    recognizer.sessionStarted = () => {
      console.log("Started recording.");
    };

    recognizer.sessionStopped = () => {
      console.log("Stopped recording.");
      setText("");
    };

    recognizer.startContinuousRecognitionAsync();
    recognizerRef.current = recognizer;
  };

  const stopListening = async () => {
    recognizerRef.current.stopContinuousRecognitionAsync();
    const { error } = await getSoapNotes(
      userDetails?.id,
      text,
      setMessages,
      setTypingIndicatorIsActive
    );
    if (!error)
      streamClairesReply(
        "/api/generate_claire_insights",
        { user_id: userDetails?.id },
        setMessages,
        setTypingIndicatorIsActive,
        scrollToBottom,
        scrollWrapperRef,
        true
      );
  };

  const handleButtonClick = () => {
    if (isListening) {
      stopListening();
      setShowSoapNotesTooltip(true);
    } else {
      startListening();
      setShowSoapNotesTooltip(false);
    }
    setIsListening(!isListening);
  };

  if (text) console.log("YOU SAID:", text);

  return (
    <button
      className={`secondaryIconButton soapNotes${
        isListening ? "pulsateBackground" : ""
      }`}
      onClick={handleButtonClick}
      aria-label={
        isListening
          ? "stop recording for SOAP notes"
          : "record meeting for SOAP notes"
      }
    >
      <img src={SoapNotesIcon} alt="" />
    </button>
  );
};

export default SoapNotesButton;

import { Sidebar } from "../components/Sidebar";
import { Messages } from "../components/Messages";
import { useContext, useEffect, useRef, useState } from "react";
import styles from "../styles/ChatScreen.module.css";
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { SettingsModal } from "../components/SettingsModal";
import Joyride, { STATUS } from "react-joyride";
import { JoyrideTooltip } from "../components/JoyrideTooltip";
import { JoyrideContext } from "../utils/contexts/JoyrideContext";

export const ChatScreen = () => {
  const [messages, setMessages] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const bubbleRefs = useRef({});
  const scrollWrapperRef = useRef();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const {
    isJoyrideActive,
    setIsJoyrideActive,
    setUserOnboardingStatusToComplete,
  } = useContext(JoyrideContext);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const isToggleReversed = showSidebar ? "" : "sidebar-toggle-reverse";

  const bookmarkedMessages = messages.filter((msg) => msg.isBookmarked);

  const toggleSidebarView = () => {
    setShowSidebar(!showSidebar);
  };

  const onboardingSteps = [
    {
      target: "textarea",
      title: "Ask Claire questions",
      content: "You can ask Claire questions using this field.",
      disableBeacon: true,
    },
    {
      target: ".speechToText",
      title: "Speech to text",
      content:
        "Instead of typing, you can also use the speech to text function to ask Claire questions.",
      disableBeacon: true,
    },
    {
      target: ".soapNotes",
      title: "Record patient interactions",
      content:
        "You can click here to start recording a patient interaction to auto-generate SOAP notes. Be sure to record the entire patient interaction for best results. To stop recording, click the button again. Your notes will appear in the chat window.",
      disableBeacon: true,
    },
    {
      target: ".sidebarToggleOutside",
      title: "Bookmarked replies",
      content:
        "You can open the sidebar to view replies from Claire you have bookmarked.",
      disableBeacon: true,
    },
    {
      target: ".versionContainer",
      title: "Return home",
      content: "Click here to head back to the home screen.",
      disableBeacon: true,
    },
    {
      target: ".profile",
      title: "Profile",
      content: "Click here to get help, view settings, and sign out.",
      disableBeacon: true,
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setIsJoyrideActive(false);
      setUserOnboardingStatusToComplete();
    }
  };

  return (
    <div className={styles.chatScreen}>
      <Sidebar
        bookmarkedMessages={bookmarkedMessages}
        bubbleRefs={bubbleRefs}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        toggleSidebarView={toggleSidebarView}
        isToggleReversed={isToggleReversed}
        scrollWrapperRef={scrollWrapperRef}
      />
      <Messages
        messages={messages}
        setMessages={setMessages}
        bubbleRefs={bubbleRefs}
        toggleSidebarView={toggleSidebarView}
        isToggleReversed={isToggleReversed}
        scrollWrapperRef={scrollWrapperRef}
        setShowSettingsModal={setShowSettingsModal}
      />
      {showSettingsModal && (
        <SettingsModal setShowSettingsModal={setShowSettingsModal} />
      )}
      <Joyride
        callback={handleJoyrideCallback}
        steps={onboardingSteps}
        showProgress
        showSkipButton
        continuous
        hideBackButton
        hideCloseButton
        spotlightPadding={0}
        floaterProps={{ hideArrow: true }}
        locale={{ skip: <p>Skip tour</p>, last: "Finish" }}
        tooltipComponent={JoyrideTooltip}
        run={isJoyrideActive}
      />
    </div>
  );
};

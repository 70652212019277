// userID of 0 is User, 1 is for Claire
const createMessage = (
  userID,
  textContent,
  isBookmarked = false,
  soapNotes = null
) => {
  return {
    messageID: crypto.randomUUID(),
    userID,
    textContent,
    isBookmarked,
    soapNotes,
  };
};

const coloredLog = (tag, data) => {
  console.log(`%c${tag}`, "color: yellow", data);
};

export { createMessage, coloredLog };

import styles from "../styles/TypingIndicator.module.css";

export const TypingIndicator = ({ show }) => {
  return (
    <div
      className={`${styles.indicatorContainer} ${
        show ? styles.showIndicator : ""
      }`}
      aria-live="polite"
      aria-label="claire is typing"
    >
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
    </div>
  );
};

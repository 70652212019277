import { createMessage } from "./helperFuntions";

const streamClairesReply = async (
  endpoint,
  payload,
  setMessages,
  setTypingIndicatorIsActive,
  scrollToBottom,
  scrollWrapperRef,
  hasSoapNotes = false
) => {
  // Add empty Claire message if no soap notes
  if (!hasSoapNotes) {
    const emptyClaireMessage = createMessage(1, "", false);
    setMessages((currentMessages) => [...currentMessages, emptyClaireMessage]);
  }
  setTypingIndicatorIsActive(true);

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    console.log("response received:", response);
    if (!response.ok) throw new Error("Failed to fetch Claire's response");

    // Stream the response
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();
    let readCount = 0;
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        console.log("total stream reads:", readCount);
        readCount = 0;
        break;
      }
      console.log("current decoded chunk value:", value);
      const jsonChunks = value.trim().split("\n");

      for (const jsonChunk of jsonChunks) {
        // Do not parse incomplete json chunks
        let HTMLcontent = null;
        try {
          HTMLcontent = JSON.parse(jsonChunk).content;
        } catch (err) {
          continue;
        }
        setMessages((messageHistory) => {
          const updatedHistory = [...messageHistory];
          const lastChatIndex = updatedHistory.length - 1;
          updatedHistory[lastChatIndex] = {
            ...updatedHistory[lastChatIndex],
            textContent: (
              <div
                dangerouslySetInnerHTML={{
                  __html: HTMLcontent,
                }}
              />
            ),
          };
          return updatedHistory;
        });

        // Auto-scroll to bottom of Claire's message as it generates
        if (
          scrollWrapperRef.current.scrollTop +
            scrollWrapperRef.current.clientHeight >=
          scrollWrapperRef.current.scrollHeight - 100
        )
          scrollToBottom();
      }
      readCount++;
    }
  } catch (err) {
    // Display error message to user
    setMessages((messageHistory) => {
      const updatedHistory = [...messageHistory];
      const lastChatIndex = updatedHistory.length - 1;
      updatedHistory[lastChatIndex] = {
        ...updatedHistory[lastChatIndex],
        textContent: "ERROR: Something went wrong. Please try again.",
      };
      return updatedHistory;
    });
    console.error(err);
  } finally {
    setTypingIndicatorIsActive(false);
  }
};

export { streamClairesReply };

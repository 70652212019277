import styles from "../styles/Tooltip.module.css";

const Tooltip = ({ text, isVisible, children }) => {
  return (
    <div className={styles.tooltipContainer} role="tooltip">
      {children}
      {isVisible && <div className={styles.tooltip}>{text}</div>}
    </div>
  );
};

export default Tooltip;

import {
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { LandingPage } from "./screens/LandingPage";
import { LoadingScreen } from "./screens/LoadingScreen";
import "./styles/global.css";
import "./styles/globalMUI.css";
import { Home } from "./screens/Home";

const App = () => {
  const { inProgress } = useMsal();

  if (inProgress === "logout") {
    return <LoadingScreen />;
  }

  return (
    <>
      <UnauthenticatedTemplate>
        <LandingPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Home />
      </AuthenticatedTemplate>
    </>
  );
};

export default App;

import { useContext, useEffect, useRef, useState } from "react";
import Avatar from "../assets/avatar.png";
import ClarifiLogo from "../assets/clarifi-logo.png";
import styles from "../styles/Header.module.css";
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import { GraphClientContext } from "../utils/contexts/GraphClientContext";

export const Header = ({ setShowSettingsModal }) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef();
  const avatarRef = useRef();
  const { instance } = useMsal();
  const { userDetails } = useContext(GraphClientContext);

  useEffect(() => {
    const handleClickOutsidePopup = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        event.target !== avatarRef.current
      ) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutsidePopup);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsidePopup);
    };
  }, [showPopup]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleSignOutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  const handleGetHelpRedirect = () => {
    window.open(
      "https://clarifi-technologies.atlassian.net/servicedesk/customer/portal/8",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleSettingsRedirect = () => {
    setShowSettingsModal(true);
  };

  const popupMenuItems = [
    { title: "Get help", onClick: handleGetHelpRedirect },
    { title: "Settings", onClick: handleSettingsRedirect },
    { title: "Sign out", onClick: handleSignOutRedirect },
  ];

  return (
    <header className={styles.header}>
      <Link
        to="/"
        className={`${styles.versionContainer} versionContainer`}
        aria-label="redirect to home page"
      >
        <img className={styles.logo} src={ClarifiLogo} alt="" />
        <div>
          <span className={styles.versionClaire}>Claire</span>
          <span className={styles.versionNumber} aria-label="version">
            {" "}
            1.0
          </span>
        </div>
      </Link>
      <div className={styles.positionRight}>
        <button
          className={`secondaryIconButton profile ${styles.avatar}`}
          onClick={togglePopup}
          ref={avatarRef}
          aria-label={showPopup ? "close menu" : "open menu"}
          aria-haspopup="menu"
          aria-expanded={showPopup}
          aria-controls="menu"
        >
          <img src={Avatar} alt="User avatar" />
        </button>
      </div>
      {showPopup && (
        <nav id="menu" className={styles.popup} ref={popupRef} role="menu">
          <div className={styles.userDetails}>
            {" "}
            <img src={Avatar} alt="User avatar" />
            <p className={styles.name}>{userDetails?.displayName}</p>
            <p className={styles.email}>{userDetails?.mail}</p>
          </div>
          <ul className={styles.popupList}>
            {popupMenuItems.map((item) => (
              <li key={item.title}>
                <button className={styles.button} onClick={item.onClick}>
                  {item.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </header>
  );
};

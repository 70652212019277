import styles from "../styles/ChatBubble.module.css";

const generateJSXListItems = (obj) => {
  return (
    <>
      {Object.entries(obj).map(([key, value]) => {
        const displayValue = value === "None" ? "" : value;
        if (typeof value === "object") {
          return (
            <li key={key}>
              <strong>{key}</strong>:<ul>{generateJSXListItems(value)}</ul>
            </li>
          );
        } else {
          return (
            <li key={key}>
              <strong>{key}</strong>: {displayValue}
            </li>
          );
        }
      })}
    </>
  );
};

const formatSoapNotes = (obj) => {
  const sectionsOrder = ["Subjective", "Objective", "Assessment", "Plan"];
  return (
    <section className={styles.soapNotesContainer}>
      {sectionsOrder.map((section) => {
        if (Object.prototype.hasOwnProperty.call(obj, section)) {
          return (
            <div key={section}>
              <strong>{section.toUpperCase()}</strong>
              <ul>{generateJSXListItems(obj[section])}</ul>
            </div>
          );
        }
        return null;
      })}
    </section>
  );
};

export { formatSoapNotes };

import { Link, useNavigate } from "react-router-dom";
import { Header } from "../components/Header";
import { SettingsModal } from "../components/SettingsModal";
import { useContext, useState } from "react";
import styles from "../styles/Home.module.css";
import Joyride, { STATUS } from "react-joyride";
import { JoyrideTooltip } from "../components/JoyrideTooltip";
import { JoyrideContext } from "../utils/contexts/JoyrideContext";

export const Home = () => {
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const {
    isJoyrideActive,
    setIsJoyrideActive,
    setUserOnboardingStatusToComplete,
  } = useContext(JoyrideContext);
  const navigate = useNavigate();

  const onboardingSteps = [
    {
      target: "body",
      title: "Welcome to Claire 1.0",
      content:
        "Claire is a dynamic assistant meant to offer guidance and decision support. Let's go on a brief tour of what Claire can do.",
      placement: "center",
      disableBeacon: true,
    },
    {
      target: ".mainContent",
      title: "Talk to Claire",
      content: "You can click here to go to Claire's chat page.",
      disableBeacon: true,
      data: { primaryButtonStatus: "continue" },
      locale: { close: "Continue" },
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status } = data;

    // End onboarding tour when user skips tour
    if ([STATUS.SKIPPED].includes(status)) {
      setIsJoyrideActive(false);
      setUserOnboardingStatusToComplete();
    }

    // Navigate to next joyride instance in ChatScreen when user completes first joyride instance
    if ([STATUS.FINISHED].includes(status)) {
      navigate("/claire");
    }
  };

  return (
    <div className={styles.homeContainer}>
      {showSettingsModal && (
        <SettingsModal setShowSettingsModal={setShowSettingsModal} />
      )}
      <Header setShowSettingsModal={setShowSettingsModal} />
      <main className={`${styles.mainContent} mainContent`}>
        <Link to="/claire" className={styles.link}>
          <iframe
            src="/claireStateAnimations/index.html"
            width="320"
            height="320"
          ></iframe>
          Talk to Claire
        </Link>
      </main>
      <Joyride
        callback={handleJoyrideCallback}
        steps={onboardingSteps}
        showProgress
        showSkipButton
        continuous
        hideBackButton
        hideCloseButton
        spotlightPadding={0}
        floaterProps={{ hideArrow: true }}
        tooltipComponent={JoyrideTooltip}
        run={isJoyrideActive}
      />
    </div>
  );
};

import styles from "../styles/LoadingScreen.module.css";
import LogoImage from "../assets/clarifi-empty-circle-logo.png";

export const LoadingScreen = () => {
  return (
    <main className={styles.loadingScreenContainer}>
      <img src={LogoImage} className={styles.logo} alt="loading content" />
    </main>
  );
};

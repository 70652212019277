import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../utils/msalConfig";
import { Socials } from "../components/Socials";
import styles from "../styles/LandingPage.module.css";

export const LandingPage = () => {
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleSignUpRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create",
      })
      .catch((error) => console.log(error));
  };

  return (
    <main className={styles.landingPage}>
      <section className={styles.card}>
        <div className={styles.titleContainer}>
          <p>Welcome to Clarifi.</p>
          <p>Get started below</p>
        </div>
        <div className={styles.buttonsContainer}>
          <button onClick={handleSignUpRedirect} className="secondaryButton">
            Sign up
          </button>
          <button onClick={handleLoginRedirect} className="primaryButton">
            Login
          </button>
        </div>
      </section>
      <footer className={styles.footer}>
        <Socials />
        <p>&#169; Clarifi Technologies Inc. | All Rights Reserved</p>
      </footer>
    </main>
  );
};

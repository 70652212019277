import { forwardRef } from "react";
import styles from "../styles/JoyrideTooltip.module.css";

export const JoyrideTooltip = forwardRef(
  (
    {
      continuous,
      index,
      isLastStep,
      size,
      step,
      closeProps,
      primaryProps,
      skipProps,
      tooltipProps,
    },
    ref
  ) => {
    const isContinueToNextPageStep =
      step.data?.primaryButtonStatus === "continue";

    return (
      <div {...tooltipProps} ref={ref} className={styles.tooltipContainer}>
        <h4>{step.title}</h4>
        <p>{step.content}</p>
        <div className={styles.buttonsContainer}>
          <button {...skipProps} className="secondaryButton">
            Skip tour
          </button>
          {!isLastStep && continuous && (
            <button {...primaryProps} className="primaryButton">
              Next
            </button>
          )}
          {isLastStep && !isContinueToNextPageStep && (
            <button {...closeProps} className="primaryButton">
              Finish
            </button>
          )}
          {isContinueToNextPageStep && (
            <button {...closeProps} className="primaryButton">
              Continue
            </button>
          )}
        </div>
      </div>
    );
  }
);

JoyrideTooltip.displayName = "JoyrideTooltip";

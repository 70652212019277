import EmptyBookmark from "../assets/bookmark-border.svg";
import FilledBookmark from "../assets/bookmark-filled.svg";
import { TypingIndicator } from "./TypingIndicator";
import Collapsible from "react-collapsible";
import { formatSoapNotes } from "../utils/formatSoapNotes";
import styles from "../styles/ChatBubble.module.css";
import TextToSpeechButton from "./TextToSpeechButton";
import Tooltip from "./Tooltip";
import { useState } from "react";

export const ChatBubble = ({
  message,
  setMessages,
  bubbleRefs,
  isLast,
  typingIndicatorIsActive,
}) => {
  const { messageID, userID, textContent, isBookmarked, soapNotes } = message;
  const isUser = userID === 0;
  const bookmarkStyle = isBookmarked ? FilledBookmark : EmptyBookmark;
  const [showTextToSpeechTooltip, setShowTextToSpeechTooltip] = useState(true);
  const [showBookmarkTooltip, setShowBookmarkTooltip] = useState(true);

  const handleBookmarkClick = () => {
    // toggle bookmark status of selected message from Claire
    setMessages((prevMessages) => {
      const prevMessagesCopy = [...prevMessages];
      const updatedMessages = prevMessagesCopy.map((msg) => {
        if (msg.messageID !== messageID) return msg;
        const newBookmarkStatus = !msg.isBookmarked;
        return { ...msg, isBookmarked: newBookmarkStatus };
      });
      return updatedMessages;
    });
    setShowBookmarkTooltip(!showBookmarkTooltip);
  };

  return (
    <section
      className={`${styles.bubbleContainer} ${
        isUser ? styles.bubbleRight : styles.bubbleLeft
      }`}
      ref={(domNode) => (bubbleRefs.current[messageID] = domNode)}
    >
      <article
        className={styles.textContentContainer}
        aria-live="polite"
        aria-atomic="true"
        aria-busy={typingIndicatorIsActive}
      >
        {soapNotes && (
          <Collapsible
            trigger="VIEW SOAP NOTES"
            triggerTagName="button"
            triggerClassName={styles.trigger}
            triggerOpenedClassName={styles.triggerOpened}
            classParentString={styles.collapsible}
          >
            {formatSoapNotes(soapNotes)}
          </Collapsible>
        )}
        {textContent}
        {isLast && !isUser && (
          <TypingIndicator show={typingIndicatorIsActive} />
        )}
      </article>
      {!isUser && (
        <div className={styles.buttonsContainer}>
          <Tooltip
            text="Start text to speech"
            isVisible={showTextToSpeechTooltip}
          >
            <TextToSpeechButton
              text={textContent}
              setShowTextToSpeechTooltip={setShowTextToSpeechTooltip}
            />
          </Tooltip>
          <Tooltip text="Bookmark message" isVisible={showBookmarkTooltip}>
            <button
              className="secondaryIconButton"
              onClick={handleBookmarkClick}
              aria-label="bookmark message"
              aria-pressed={isBookmarked}
            >
              <img src={bookmarkStyle} alt="" />
              {isBookmarked && (
                <span className={styles.bookmarkText}>Bookmarked</span>
              )}
            </button>
          </Tooltip>
        </div>
      )}
    </section>
  );
};

import { useContext, useEffect, useRef, useState } from "react";
import { createMessage } from "../utils/helperFuntions";
import { streamClairesReply } from "../utils/streamClairesReply";
import styles from "../styles/InputBar.module.css";
import SoapNotesButton from "./SoapNotesButton";
import SpeechToTextButton from "./SpeechToTextButton";
import Tooltip from "./Tooltip";
import ArrowIcon from "../assets/send-arrow.svg";
import { GraphClientContext } from "../utils/contexts/GraphClientContext";

export const InputBar = ({
  setMessages,
  setTypingIndicatorIsActive,
  scrollToBottom,
  scrollWrapperRef,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showSoapNotesTooltip, setShowSoapNotesTooltip] = useState(true);
  const [showSpeechToTextTooltip, setShowSpeechToTextTooltip] = useState(true);
  const textareaRef = useRef(null);
  const { userDetails } = useContext(GraphClientContext);

  useEffect(() => {
    // expand textarea with longer text input
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
    scrollToBottomOfTextarea();
  }, [inputValue]);

  const scrollToBottomOfTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight - 30;
    }
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (!inputValue.trim()) return; // Prevent sending empty messages

    // Display user's message
    const userMessage = createMessage(0, inputValue);
    setMessages((currentMessages) => [...currentMessages, userMessage]);
    setInputValue("");

    streamClairesReply(
      "/api/Claire",
      { user_id: userDetails?.id, question: inputValue },
      setMessages,
      setTypingIndicatorIsActive,
      scrollToBottom,
      scrollWrapperRef
    );
  };

  const handleInputOnChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputOnKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) handleSendMessage(event);
  };

  return (
    <section className={styles.inputBarContainer}>
      <div className={styles.buttonsContainer}>
        <Tooltip
          text="Record meeting for SOAP notes"
          isVisible={showSoapNotesTooltip}
        >
          <SoapNotesButton
            setMessages={setMessages}
            setTypingIndicatorIsActive={setTypingIndicatorIsActive}
            scrollWrapperRef={scrollWrapperRef}
            scrollToBottom={scrollToBottom}
            setShowSoapNotesTooltip={setShowSoapNotesTooltip}
          />
        </Tooltip>
        <Tooltip
          text="Start speech to text"
          isVisible={showSpeechToTextTooltip}
        >
          <SpeechToTextButton
            setInputValue={setInputValue}
            setShowSpeechToTextTooltip={setShowSpeechToTextTooltip}
          />
        </Tooltip>
      </div>
      <form onSubmit={handleSendMessage} className={styles.form}>
        <label htmlFor="message-input" className={styles.visuallyHidden}>
          Write your message to Claire
        </label>
        <textarea
          id="message-input"
          value={inputValue}
          onChange={handleInputOnChange}
          onKeyDown={handleInputOnKeyDown}
          className={styles.textarea}
          placeholder="Message Claire..."
          ref={textareaRef}
          rows="1"
        />
        <button
          className={`primaryIconButton ${styles.positionRight}`}
          type="submit"
          aria-label="send message"
        >
          <img src={ArrowIcon} alt="" />
        </button>
      </form>
      <div className={styles.citeSources}>
        Claire provides guidance based on reliable sources, which are cited in
        her replies—feel free to check them out!
      </div>
    </section>
  );
};

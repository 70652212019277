import { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { GraphClientContext } from "./GraphClientContext";

export const JoyrideContext = createContext(null);

// Synchronizes the active state of the two instances of joyride--one in Home and another in ChatScreen
export const JoyrideProvider = ({ children }) => {
  const [isJoyrideActive, setIsJoyrideActive] = useState(false);
  const { accounts, instance } = useMsal();
  const { graphClient } = useContext(GraphClientContext);

  // Initialize isJoyrideActive state based on onboardingCompleted value in user's Graph profile
  useEffect(() => {
    const fetchData = async () => {
      if (accounts.length > 0 && graphClient) {
        try {
          const { extensions } = await graphClient
            .api("/me?$expand=extensions")
            .get();
          let onboardingCompleted = extensions.some(
            (extension) => extension.onboardingCompleted === true
          );
          setIsJoyrideActive(!onboardingCompleted);
        } catch (error) {
          console.error(
            "Error fetching user's onboardingStatus in Graph profile:",
            error
          );
        }
      }
    };
    fetchData();
  }, [instance, accounts, graphClient]);

  const setUserOnboardingStatusToComplete = async () => {
    try {
      const user = await graphClient.api("/me").get();
      const extension = {
        "@odata.type": "#microsoft.graph.openTypeExtension",
        extensionName: "onboardingCompleted",
        onboardingCompleted: true,
      };
      await graphClient.api(`/users/${user.id}/extensions`).post(extension);
      console.log(
        "Updated user's onboarding status to complete in Graph profile"
      );
    } catch (error) {
      console.error(
        "Error updating user's onboardingStatus in graph profile:",
        error
      );
    }
  };

  return (
    <JoyrideContext.Provider
      value={{
        isJoyrideActive,
        setIsJoyrideActive,
        setUserOnboardingStatusToComplete,
      }}
    >
      {children}
    </JoyrideContext.Provider>
  );
};
